import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MySignature from "../images/Signature.svg"

import "../Style/EssayPage.css"
import TitleUnderlined from "../components/TitleUnderlined"
import MyLogoFixed from "../components/LogoFixed"

const FrameNinja = ({data}) => (
 <div> 
    <SEO title="FrameNinja — Phil Murphy" />

    <Helmet>

        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="Here I talk about my experience working on FrameNinja and what I think went wrong.">
        </meta>

        <meta name="og:description" content="Here I talk about my experience working on FrameNinja and what I think went wrong.">
        </meta>

        <meta name="description" content="Here I talk about my experience working on FrameNinja and what I think went wrong.">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="Here I talk about my experience working on FrameNinja and what I think went wrong.">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>




    <div className="EssayPageWrapper">

        <essayheader><MyLogoFixed /></essayheader>

        <essaycontent>

        <p className="EssayTitlePage">FrameNinja</p>

        <TitleUnderlined title="Introduction" color="green" />
        <p className="EssayContentPage">In the summer of 2018, Anton, a great engineer, and my future co-founder, and I was thinking about how we could improve the livestreaming industry and make the process better for the broadcasters, as well as more appealing for the viewers.</p>
        
        <p className="EssayContentPage">After a while, Anton made a small prototype that we saw potential in, and that's how we started working on the app, which we called FrameNinja.</p>

        <TitleUnderlined title="What is it?" color="yellow" />
        <p className="EssayContentPage">FrameNinja is a set of tools that allows streamers to create animated widgets and scripts for interacting with their audience, as well as having essential control panel over the broadcast and community.</p>

        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/aGAxRuJfvks" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

      <TitleUnderlined title="What problem we've been trying to solve?" color="orange" />
        <p className="EssayContentPage">We were big fans of interactive livestreaming, as well as big haters of all the known and existing solutions. — They were all super outdated, terribly archaic, and mostly useless when it comes to creating something unique and not "by the book".</p>
        
        <p className="EssayContentPage">So, the desire to create our own interactive widgets, without any limits and having to code, led us to the idea of creating a toolset that could make it doable.</p>

        <p className="EssayContentPage">You can also learn more about what's wrong with the industry and what fundamental parts we've tried to rethink, in my essay <Link to="/Community-Seeking"><b>Community Seeking</b></Link></p>

        <p className="EssayContentPage">It's reflects our entire philosophy and will give you the most accurate understanding of what we believe in.</p>

        <br />
        <br />

        <p>But for now...</p>

        <TitleUnderlined title="The way it works" color="green" />
        <p className="EssayContentPage">We created a great 6-minute video that perfectly describes all the features and potential of the app:</p>

        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/TbUgYKJKcbQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <p className="EssayAddContentPage"><i>But here's in a nutshell:</i></p>


        <TitleUnderlined title="Chains" color="orange" />
        <p className="EssayContentPage">The epicenter of the entire app has been Chains. Here streamers can connect different commands with each other, thus creating scenarios.</p>

        <p className="EssayAddContentPage"><i>Here's what it looked like when Anton made our first prototype:</i></p>

        <div className="ImageWrapperSideBySide">

        <div>

                <div className="EssaySmallImagePage">
                    <a href="../static/abab60ba8daff90cb9c7b7c2a8237f96/11527/ninja1.png" target="_blank" rel="noopener">
                        <Img 
                            fluid={data.Ninja1.childImageSharp.fluid}
                        />
                    </a>
                    </div>

        </div>

        <div>

                <div className="EssaySmallImagePage">
                <a href="../static/d43c88f64ca8f42745139a144672ad23/5b126/ninja2.png" target="_blank" rel="noopener">
                    <Img 
                        fluid={data.Ninja2.childImageSharp.fluid}
                    />
                </a>
                </div>

        </div>

        </div>

        <br /><br />
        <p className="EssayAddContentPage"><i>And here's what it finally turned into:</i></p>

        <div className="EssayImagePage">
          <a href="../static/0a67d5b91bd90c2766312c771b91f35e/51570/ninja3.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja3.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <div className="EssayImagePage">
          <a href="../static/a71947a155ef7577993da12182b31b43/78a75/ninja4.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja4.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <div className="ImageWrapperSideBySide">

              <div>

                        <div className="EssayImagePage">
                      <a href="../static/c218f15ea08ec199e105648ad130646a/6050d/ninja5.png" target="_blank" rel="noopener">
                        <Img 
                            fluid={data.Ninja5.childImageSharp.fluid}
                            style={{ borderRadius: `10px`}}
                        />
                      </a>
                    </div>

              </div>

              <div>

                        <div className="EssayImagePage">
                      <a href="../static/cd3ba6a1e28391416ce26f84f5143503/da63c/ninja6.png" target="_blank" rel="noopener">
                        <Img 
                            fluid={data.Ninja6.childImageSharp.fluid}
                            style={{ borderRadius: `10px`}}
                        />
                      </a>
                    </div>

              </div>

            </div>

        <div className="EssayImagePage">
          <a href="../static/9376a4e7cad3d4f59e2e899796efb993/c797e/ninja7.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja7.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <p className="EssayContentPage">In the editor, creators can change the name, hotkey, control variables, and add commands.</p>
        <p className="EssayContentPage">During the first months of development, the editor has changed dramatically several times. The main problem we had was how difficult it is to separate workspaces so as not to overload the interface.</p> 
        <p className="EssayContentPage">Eventually, we came to the idea of making a different area for the command bar to separate it from the variables and other settings.</p>


        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/gLg3IVqNniw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div className="ListBox">
            <p className="ListTitleRed">Downsides</p>
            <ul>
                <li>Not comfortable to use when you have more than 4-5 commands. It's easy to get lost — the best solution would be to switch from a vertical to a horizontal canvas.</li>
                <li>A lot of confusing variable types when using for the first time.</li>
                <li>Some elements are not obvious, such as a checkbox next to the text inputs (in fact, they has been made so you can write text as in a normal input, if necessary)</li>
            </ul>
        </div>

        <TitleUnderlined title="Scripts" color="green" />
        <p className="EssayContentPage">After a long time thinking about how to make a section that would let streamers run Chains automatically when a certain condition or event occurs, we invented the Scripts tab.</p>

        <div className="EssayImagePage">
          <a href="../static/05d53870c423bca954c7564327cdb688/4f38f/ninja8.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja8.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <div className="EssayImagePage">
          <a href="..static/1168f5c9e47a70094c15dd184a498110/d600e/ninja10.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja10.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <p className="EssayContentPage">When creating a new script, you can select one of the numerous triggers</p>

        <div className="EssayImagePage">
          <a href="../static/6d0ad43c8ed2cbe037b5ad7e46463454/cb6a4/ninja9.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja9.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <p className="EssayContentPage">Each trigger has its own special conditions you can use to make more complex scenarios</p>

        <div className="EssayImagePage">
          <a href="../static/4a2721510594a1ca797dd9946dd58621/a0352/ninja11.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja11.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <p className="EssayAddContentPage">And here's video explanation:</p>

        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/BQJjvevDavM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>


        <TitleUnderlined title="Autoruns" color="yellow" />
        <p className="EssayContentPage">For cases when creator wants Chains to start automatically every X minutes, we've created the Autoruns tab.</p> 
        
        <p className="EssayContentPage">We used almost the same layout as in the Scripts, but added an additional switcher, using which streamers can disable all autoruns at once.</p>  

        <p className="EssayContentPage">On the top bar, we also added a checkbox that allows Autoruns work only when you go live.</p>

        <div className="EssayImagePage">
          <a href="../static/1d5e1cff78917859f436d5ca62af16a4/cb6a4/ninja12.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja12.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>
  
        <p className="EssayAddContentPage">Another cool explainer:</p>

        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/7APQdzab01Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>


        <TitleUnderlined title="Chat" color="green" />
        <p className="EssayContentPage">We loved the concept that you can connect multiple commands, thus creating some sort of chain of actions. And at some point we thought, why not do the same for the chat?</p>
        
        <p className="EssayContentPage">Sticking with the idea for a while, we added the ability to have a flexible configuration of displayed messages, which allowed to have much more complex scenarios.</p> 
        
        <p className="EssayContentPage">For instance, a scenario where you only see messages from those who won the raffle, and as a streamer, you can easily see what the winner writes and not lose the context.</p>


        <div className="EssayImagePage">
          <a href="../static/cefb1ac045f707d3b8c3cd9ffc774bdb/fc362/ninja14.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja14.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <div className="EssayImagePage">
          <a href="../static/a4f4c1197af69765689c9976233e6312/634bf/ninja15.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja15.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>


        <TitleUnderlined title="Gallery" color="yellow" />
        <p className="EssayContentPage">At some point it became clear that not all users are ready to use all the built-in functionality, so we had to come up with a solution that could let them stay on the same page with everyone.</p>
        
        <p className="EssayContentPage">Hence we created a Gallery tab that allows streamers to use templates with the most common use cases and configure everything via simple fields.</p>


        <div className="EssayImagePage">
          <a href="../static/33654b44a75dc71fbf997226d560f60d/ce2ea/ninja13.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja13.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <div className="EssayImagePage">
          <a href="../static/158e5b6db9f3c41ea23b8df94d7a1662/634bf/ninja16.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.Ninja16.childImageSharp.fluid}
                style={{ borderRadius: `10px`}}
            />
          </a>
        </div>

        <div className="Contentnderline"></div>

        <p className="EssaySecondHeaderTypePurple">Community Building</p>


        <TitleUnderlined title="Video Tutorials" color="yellow" />

        <p className="EssayContentPage">When you're building a tool for creators, it's important to work on how they'll explore and learn your app. So after going through several options of how to educate our users, we came to what worked best — full-fledged video tutorials.</p>

        <p className="EssayAddContentPage"><i>For example:</i></p>

        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/ZyUPKTY0oXo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div className="CautionBlock">
          <div className="CautionContent">
                <div className="CautionStyle">You can find the entire series of our tutorials in this → <a href="https://www.youtube.com/playlist?list=PLrDzUy97NCpj5Ezc485kkDZxu-EAWWIUW" target="_blank" rel="noopener">playlist</a>.</div>
          </div>
        </div>


        <TitleUnderlined title="Community Showcases" color="green" />
        <p className="EssayContentPage">Thanks to the help of Criken, a famous livestreamer and content creator, who mentioned FrameNinja on his Twitter, we got access to hundreds of variety streamers that started crafting incredible things using the app.</p>

        <p className="EssayAddContentPage"><i>We've shown some of them on our Twitter:</i></p>

        <div className="EssayTwitterImagePage">
          <a href="https://twitter.com/FrameNinjaHQ/status/1130289067357364225?s=20" target="_blank" rel="noopener">
            <Img 
                fluid={data.Tweet1.childImageSharp.fluid}
            />
          </a>
        </div>

        <div className="EssayTwitterImagePage">
          <a href="https://twitter.com/FrameNinjaHQ/status/1127651670983348226?s=20" target="_blank" rel="noopener">
            <Img 
                fluid={data.Tweet2.childImageSharp.fluid}
            />
          </a>
        </div>

        <br /><br />

        <TitleUnderlined title="Major Events" color="green" />
        <p className="EssayContentPage">Apart from supporting third-party creators, we also created our own shows in collaboration with big entertainers. <i>Here are a few examples:</i></p>

        <br />

        <TitleUnderlined title="Name your Pokémon" color="orange" />
        <p className="EssayContentPage">The first significant show we worked on was a series of livestreams where viewers could give names to Pokemons using an interactive raffle system we made together with Criken.</p>

        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/VEfCsneNS50" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>


        <TitleUnderlined title="24-hr Subathon" color="yellow" />
        <p className="EssayContentPage">After that, we continued working alongside and put together an entirely new system, including sub timer and automatic milestones check, for the 24-hour live marathon.</p>

        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/xfR9WvTRBeo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        
        <TitleUnderlined title="World of Warcraft" color="orange" />
        <p className="EssayContentPage">The biggest show so far that we've ever done is a collaboration of a dozen streamers who played together in World of Warcraft. We created a system that allowed them to show information about their characters, as well as arrange fun voting sessions.</p>

        <div className="EmbedVideo">
            <iframe src="https://www.youtube.com/embed/3fmljv--2oM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <p className="EssayAddContentPage">Another significant milestone has been the streamers that use FrameNinja occupied most of the popular streams on Twitch's page:</p>

        <div className="EssayImagePage">
          <a href="../static/a9c7d13f6bd851f1b5db7cf80c0a83b0/ec009/WOW.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.WOW.childImageSharp.fluid}
            />
          </a>
        </div>

        <div className="Contentnderline"></div>


        <p className="EssaySecondHeaderTypeRed">What went wrong?</p>
        <TitleUnderlined title="Missing features and UX problems" color="red" />

        <p className="EssayContentPage">Sometimes when you create a brand new product you focus on the things that you would like to have in the first place. And even if you have early adopters, they usually don't show the actual big picture either, hence they are most likely just a reflection of your own actions since you trained them, and not really let them sink in.</p>

        <p className="EssayContentPage">This is what led us to lack of important features when a large number of streamers started using the app.</p>
        
        <p className="EssayContentPage">And one of the main missing features was the ability to share ready-made sets of chains, scripts, and other presets with each other. Turned out, many creators don't have time to create everything on their own and it's easier to pay or ask someone to do it instead. </p>
        
        <p className="EssayContentPage">Unfortunately, FrameNinja didn't allow them to do it so easily and seamlessly, as they wanted.</p>

        <p className="EssayContentPage">Another omission was our error in designing and creating a more accessible link between different functions and tabs. Over time it's just become overloaded, and it was simply hard to figure out how you can do anything.</p>

        <TitleUnderlined title="Wrong audience" color="red" />
        <p className="EssayContentPage">From the very beginning, we wanted to focus on small creators, as we believed they would be most interested in a new format in order to stand out from other well-known streamers. But when we started inviting everyone to the app, we quickly came to the conclusion that small creators are not ready to use the new tools because they don't think it will help them.</p>
        <p className="EssayContentPage">Suddenly, we discovered an interest in using our tools from famous streamers.</p>
        <p className="EssayContentPage">Initially, our thoughts were based on the fact that well-known streamers don't really need anything new since they already have huge viewership. But, turned out, big streamers are the ones who actually want to use and have new "unusual things" on their broadcasts. Moreover, after several tests and collaborations with big creators, we discovered that small streamers began to be interested too, and only because of big streamers' actions.</p>
        <p className="EssayContentPage">So what's the problem? The main difference between small and large streamers has been time. Well-known and big creators didn't have time to use the tools themselves, even if they would like to have such interactive widgets and scripts. Worse, the app wasn't intended to be used in the way of using ready-made setups, so eventually, big creators simply abandoned the idea of using it on a permanent basis due to the complexity, high entry threshold, and lack of personal time to create and maintain their own interactive items, so it became hard to scale and growth.</p>


        <TitleUnderlined title="Technical Issues" color="red" />
        <p className="EssayContentPage">The technical difficulties in such a huge and complex project were obvious. When we released new features or bug-fixed old ones, we accidentally broke something else. What's worse, we made critical errors that could stress the creator's computer (CPU load @_@) to the level of a blue screen and reboot the machine right during the broadcast (due to a large number of viewers and chat activity). </p>
        
        <p className="EssayContentPage">The bright side was that we came up with some ideas how we could rewrite the core to avoid this in the future. And now I just hope that the future will come :)</p>

        <div className="Contentnderline"></div>


        <div className="CautionBlock">
          <h2 className="CautionTitle">Author's note</h2>
          <div className="CautionContent">
            <div>
                <div className="CautionStyle">As of the beginning of 2020, FrameNinja has been temporarily suspended, and we're currently working on a new livestreaming-related product, which should solve the problems we've had and reach the proper level we've always wanted. Stay tuned!</div>
                <div className="CautionStyle">If you have any questions about livestreaming or business and product development, you can shoot me a message <b>nerf@hey.com</b> or slide into my <a href="https://twitter.com/NERFWRLD" target="_blank" rel="noopener">Twitter DMs</a>.</div>
                <div className="CautionStyle">Always happy to chat!</div>
            </div>
          </div>
        </div>


        </essaycontent>







        <essayfooter>
        <div>
          <div className="Signature"><img src={MySignature} width="166" /></div>
        </div>
        </essayfooter>

    </div>

    </div>
)

export default FrameNinja

export const query = graphql`
  query {

    Tweet1: file(relativePath: { eq: "Tweet1.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    Tweet2: file(relativePath: { eq: "Tweet2.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      Ninja1: file(relativePath: { eq: "ninja1.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  
      Ninja2: file(relativePath: { eq: "ninja2.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        Ninja3: file(relativePath: { eq: "ninja3.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    
        Ninja4: file(relativePath: { eq: "ninja4.png" }) {
            childImageSharp {
              fluid(maxWidth: 3000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          Ninja5: file(relativePath: { eq: "ninja5.png" }) {
            childImageSharp {
              fluid(maxWidth: 3000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      
          Ninja6: file(relativePath: { eq: "ninja6.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            
            Ninja7: file(relativePath: { eq: "ninja7.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            Ninja8: file(relativePath: { eq: "ninja8.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            
            Ninja9: file(relativePath: { eq: "ninja9.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            Ninja10: file(relativePath: { eq: "ninja10.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            
            Ninja11: file(relativePath: { eq: "ninja11.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            Ninja12: file(relativePath: { eq: "ninja12.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            Ninja13: file(relativePath: { eq: "ninja13.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            
            Ninja14: file(relativePath: { eq: "ninja14.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            Ninja15: file(relativePath: { eq: "ninja15.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            Ninja16: file(relativePath: { eq: "ninja16.png" }) {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }


      WOW: file(relativePath: { eq: "WOW.png" }) {
         childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

  }
`